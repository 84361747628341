<template>
  <div>
    <Swiper v-if="swiperIndexList.length > 0" :data="swiperIndexList" />
    <div class="shopify-section">
      <div class="section-header flex-bet-cen">
        <div class="section-title font-medium">
          {{ $trans('推荐', '推荐') }}
        </div>
        <a href="/gallery" class="section-more">
          {{ $trans('浏览全部', '浏览全部') }}</a
        >
      </div>
      <div v-if="drawing.length < 1" class="empty-box" @click="getAllGoodsList">
        <el-empty
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        ></el-empty>
      </div>
      <div v-else class="featured-collection__product">
        <ShopItem
          :class="[
            'featured-collection__product-item',
            index === 0 ? 'featured-collection__product_first' : '',
          ]"
          v-for="(item, index) in drawing"
          :key="index"
          :data="item"
          :first="index === 0 ? true : false"
        />
      </div>
    </div>
    <div class="content-section font-light flex-column-cen">
      <span>{{
        $trans('上殊房携手知名艺术家共同制作', '上殊房携手知名艺术家共同制作')
      }}</span>
      <span>{{
        $trans('让我们走进每个作品背后的故事', '让我们走进每个作品背后的故事')
      }}</span>
    </div>
    <div class="shopify-section">
      <div class="section-header flex-bet-cen">
        <div class="section-title font-medium">
          {{ $trans('艺术家', '艺术家') }}
        </div>
        <a href="/artist" class="section-more">{{
          $trans('浏览全部', '浏览全部')
        }}</a>
      </div>
      <div v-if="blogsList.length < 1" class="empty-box" @click="getArtistList">
        <el-empty
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        ></el-empty>
      </div>
      <Blogs v-else :data="blogsList" />
    </div>
    <Footer />
  </div>
</template>
<script>
import { loadLocaleMessages } from '@/i18n/i18n'
import Swiper from '@/components/swiper/SwiperIndex.vue'
import ShopItem from '@/components/shop/ShopItem.vue'
import Blogs from '@/components/three/Blogs.vue'
import Footer from '@/components/layout/Footer.vue'
import {
  getRecommendGoodsList,
  getArtistList,
  swiperData,
} from '@/util/js/api.js'
export default {
  name: '',
  components: {
    Swiper,
    ShopItem,
    Blogs,
    Footer,
  },
  data() {
    return {
      drawing: [], // 绘画数据列表
      isGetDrawing: false, // 是否在请求绘画数据
      blogsList: [], // 艺术家数据
      isGetBlogsList: false, // 是否在请求艺术家数据
      swiperIndexList: [], // 轮播图数据
    }
  },
  metaInfo: {
    title: '上殊房',
    meta: [
      { name: 'keywords', content: '上殊房' },
      {
        name: 'description',
        content:
          '“上”意指开始、行动、积极起来。我们致力于将每一件艺术品，以多维度的方式呈现于您的眼前',
      },
    ],
  },
  created() {
    this.swiperData()
  },
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave:', to, from, next)
    const homepage = document.querySelector('.main__layout').scrollTop
    const postion = {
      homepage: homepage,
    }
    localStorage.setItem('position', JSON.stringify(postion))
    next()
  },
  mounted() {
    this.getAllGoodsList()
    this.getArtistList()
    if (document.querySelector('.main__layout')) {
      setTimeout(() => {
        this.tunrnTo()
      }, 300)
    }
  },
  methods: {
    globals(str) {
      return this.$t(str)
    },
    tunrnTo() {
      let postion = localStorage.getItem('position')
      postion = JSON.parse(postion)
      if (postion && postion['homepage']) {
        document
          .querySelector('.main__layout')
          .scrollTo({ top: postion['homepage'], behavior: 'smooth' })

        localStorage.setItem('position', JSON.stringify({}))
      }
    },
    // 获取所有商品数据
    getAllGoodsList() {
      if (this.isGetDrawing) {
        this.$message.warning(
          this.$trans('请不要频繁发送请求', '请不要频繁发送请求')
        )
        return false
      } else {
        this.isGetDrawing = true
        getRecommendGoodsList()
          .then((res) => {
            this.drawing = res.data ? JSON.parse(JSON.stringify(res.data)) : []
            this.isGetDrawing = false
            res.data.forEach((item) => {
              if (item.transferList) {
                loadLocaleMessages(item.transferList, `goods-${item.id}`)
              }
            })
          })
          .catch(() => {
            this.isGetDrawing = false
            this.$message.error(
              this.$trans('网络错误,没有获取到数据', '网络错误,没有获取到数据')
            )
          })
      }
    },
    // 获取艺术家数据
    getArtistList() {
      if (this.isGetBlogsList) {
        this.$message.warning(
          this.$trans('请不要频繁发送请求', '请不要频繁发送请求')
        )
        return false
      } else {
        this.isGetBlogsList = true
        getArtistList(5)
          .then((res) => {
            this.blogsList = res.data.rows
              ? JSON.parse(JSON.stringify(res.data.rows)).map((item) => {
                  if (item.content) {
                    let arr = item.content.split('<sign>')
                    item.title = arr[0]
                  }
                  return item
                })
              : []
            res.data.rows.forEach((item) => {
              if (item.transferList) {
                loadLocaleMessages(item.transferList, `artist-${item.id}`)
              }
            })
            this.isGetBlogsList = false
          })
          .catch(() => {
            this.isGetBlogsList = false
            this.$message.error(
              this.$trans('网络错误,没有获取到数据', '网络错误,没有获取到数据')
            )
          })
      }
    },
    // 获取轮播图数据
    swiperData() {
      swiperData()
        .then((res) => {
          if (res.data) {
            this.swiperIndexList = res.data.rows
              ? JSON.parse(JSON.stringify(res.data.rows))
              : []
            res.data.rows.forEach((item) => {
              if (item.transferList) {
                loadLocaleMessages(item.transferList, `goods-${item.spuId}`)
              }
              if (item.artistTransferList) {
                loadLocaleMessages(
                  item.artistTransferList,
                  `artist-${item.artistId}`
                )
              }
            })
          } else {
            this.swiperIndexList = []
          }
        })
        .catch(() => {
          this.$message.error(
            this.$trans('网络错误,没有获取到数据', '网络错误,没有获取到数据')
          )
        })
    },
  },
}
</script>
<style scoped>
.shopify-section {
  margin: 80px 60px;
  max-width: 1700px;
}
.section-header {
  margin-bottom: 10px;
}
.section-title {
  color: #000000;
  font-size: 20px;
  font-weight: bold;
}
.section-more {
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  border-bottom: 2px solid #000000;
}
.featured-collection__product {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 30px;
  row-gap: 30px;
}
.featured-collection__product-item {
  position: relative;
}
.featured-collection__product_first {
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 3;
}
.content-section {
  text-align: center;
  height: 400px;
  background: #111111;
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 6px;
  justify-content: center;
  line-height: 2;
}
@media screen and (min-width: 1200px) {
  .featured-collection__product {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 1600px) {
  .featured-collection__product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
