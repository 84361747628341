<template>
  <div class="blog-component">
    <div class="blog-item" v-for="(item, index) in data" :key="index">
      <a :href="'/artist/detail/' + item.id" class="blog-item__content">
        <div class="blog-item_img-wrap">
          <img
            v-lazy="item.profileImg1.split(',')[0]"
            alt=""
            class="blog-item_img"
          />
          <div class="mask"></div>
        </div>
        <span class="blog-item_title">{{
          $trans(`artist-${item.id}.detailTitle`, item.detailTitle)
        }}</span>
        <span class="blog-item_author">{{
          '「' + $trans(`artist-${item.id}.name`, item.name) + '」'
        }}</span>
        <span class="blog-item_content text-over-2">{{
          $trans(`artist-${item.id}.brief`, item.brief)
        }}</span>
      </a>
      <a :href="'/artist/detail/' + item.id" class="blog-item__more"
        ><span>{{ $trans('继续阅读', '继续阅读') }}</span></a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    data: {
      typeof: Array,
    },
  },
}
</script>
<style scoped>
.blog-item {
  margin-bottom: 50px;
}
.blog-item__content {
  display: flex;
  flex-direction: column;
}
.blog-item_img-wrap {
  position: relative;
  overflow: hidden;
}
.blog-item_img-wrap .mask {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  display: none;
}
.blog-item_img-wrap:hover .mask {
  display: block;
}
.blog-item_img-wrap:hover img {
  transform: scale(1.2);
}
img {
  transition: all 0.5s;
  transform-origin: center;
  display: block;
  width: 100%;
  height: auto;
}
.blog-item_title {
  color: #000000;
  font-size: 20px;
  margin-top: 25px;
  margin-bottom: 30px;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
.blog-item_author {
  font-size: 12px;
  margin-left: -5px;
}
.blog-item_content {
  font-size: 12px;
  font-family: 'myCoolFontLight';
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1px;
}
.blog-item__more {
  font-size: 12px;
  font-family: 'myCoolFontMedium';
  display: flex;
  justify-content: flex-end;
  transform-origin: 100% 100%;
  transform: scale(0.8);
}
.blog-item__more span {
  padding-bottom: 3px;
  border-bottom: 1px solid;
}
@media screen and (min-width: 1200px) {
  .blog-component {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
  }
}
@media screen and (min-width: 1920px) {
  .blog-component {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
  }
}
</style>
