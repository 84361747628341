<template>
  <div class="swiper_container-wrap">
    <div class="swiper-container swiper-containerA swiper-container-a">
      <div class="swiper-wrapper swiper-wrapper-a">
        <div
          class="swiper-slide flex-cen-cen"
          v-for="(item, index) in data"
          :key="index"
        >
          <div
            class="iframe-wrap flex-cen-cen"
            :style="{ background: item.bgColor }"
          >
            <div :class="[item.styleType == 0 ? '' : 'border-iframe-outer']">
              <div :class="[item.styleType == 0 ? '' : 'border-iframe-inner']">
                <img :src="item.imagePath" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-container swiper-containerB swiper-container-a">
      <div class="swiper-wrapper swiper-wrapper-a">
        <div class="swiper-slide" v-for="(item, index) in data" :key="index">
          <div class="mask flex-column-cen">
            <span class="swiper-mask__limit">{{
              $trans('限量发售', '限量发售')
            }}</span>
            <span class="swiper-mask__name"
              >《{{ $trans(`goods-${item.spuId}.title`, item.title) }}》</span
            >
            <span class="swiper-mask__from">{{ item.from }}</span>
            <span class="swiper-mask__author"
              >{{ $trans('作者', '作者') }}：{{
                $trans(`artist-${item.artistId}.name`, item.artistName)
              }}</span
            >
            <a
              :href="'/shop/detail/' + item.spuId"
              class="swiper-mask__btn flex-cen-cen"
            >
              BUY NOW <i class="iconfont icon-arrowRight"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
import Swiper from 'swiper'
export default {
  name: '',
  props: {
    data: {
      typeof: Array,
    },
  },
  data() {
    return {
      swiperA: null,
      swiperB: null,
    }
  },
  mounted() {
    this.swiperA = new Swiper('.swiper-containerA', {
      loop: true,
      controller: {
        control: this.swiperB,
      },
      watchSlidesProgress: true,
    })
    this.swiperB = new Swiper('.swiper-containerB', {
      loop: true,
      // autoplay:{
      //     delay: 2000,
      //     disableOnInteraction:false
      // },
      speed: 1500,
      controller: {
        control: this.swiperA,
      },
      spaceBetween: 80,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  },
}
</script>
<style scoped>
.swiper_container-wrap {
  position: relative;
}
.swiper_container-wrap .swiper-container-a {
  padding-bottom: 57px;
}
.swiper_container-wrap .swiper-containerB {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.swiper_container-wrap .swiper-containerB .mask {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
  font-weight: bold;
  font-family: 'myCoolFontBold';
}
.swiper-mask__limit {
  font-weight: bold;
  letter-spacing: 2px;
  font-size: 16px;
  margin-bottom: 50px;
}
.swiper-mask__name {
  font-size: 25px;
  letter-spacing: 2px;
  line-height: 30px;
}
.swiper-mask__from {
  font-size: 25px;
  letter-spacing: 2px;
  line-height: 30px;
  margin-bottom: 15px;
}
.swiper-mask__author {
  font-size: 16px;
  margin-bottom: 60px;
}
.swiper-mask__btn {
  width: 130px;
  height: 40px;
  background: #ffffff;
  border-radius: 2px;
  color: #000000;
  font-size: 12px;
  font-style: italic;
  word-spacing: 5px;
}
.swiper-mask__btn .iconfont {
  margin-left: 10px;
  font-size: 18px;
}
.iframe-wrap {
  width: 100%;
  height: 600px;
}
.border-iframe-outer {
  display: inline-block;
  border: 12px solid #111111;
}
.border-iframe-inner {
  border: 12px solid #ffffff;
}
img {
  max-width: 400px;
  max-height: 400px;
  width: auto;
  height: auto;
  display: block;
}
</style>
<style>
.swiper-pagination-clickable .swiper-pagination-bullet {
  margin-right: 6px;
  width: 10px;
  height: 10px;
}

.swiper-pagination-bullet-active {
  background: #000000;
}

.swiper-pagination {
  /* height: 21px; */
  left: 50%;
  transform: translateX(-50%);
  bottom: 18px;
}

.swiper-pagination-number {
  left: 20%;
  position: absolute;
  bottom: 21px;
}
.swiper-button-next,
.swiper-button-prev {
  top: unset;
  bottom: 21px;
  height: 16px;
}

.swiper-button-next {
  left: 30%;
}

.swiper-button-prev {
  left: 10%;
}

.swiper-pagination {
  z-index: 5;
}
</style>
